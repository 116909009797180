import { createSlice } from '@reduxjs/toolkit';
import { selectFilteredPromos } from './promoSlice';
import { PromoConst } from 'util/Constants';

const initialState = {
	selectedPromoId: null,
	selectedTicketTypeName: null,
};

// This slice has not been tested completely, so be warned: you may need to fix some small issues.
export const selectedMultiselectOptions = createSlice({
	name: 'selectedMultiSelectOptions',
	initialState,
	reducers: {
		setSelectedPromoId: (state, action) => {
			state.selectedPromoId = action.payload;
		},
		setSelectedTicketTypeName: (state, action) => {
			state.selectedTicketTypeName = action.payload;
		},
	},
});

export const { setSelectedPromoId } = selectedMultiselectOptions.actions;

export default selectedMultiselectOptions.reducer;

export function selectSelectedPromo(state) {
	if (
		state.selectedMultiSelectOptions.selectedPromoId ===
		PromoConst.FULL_PRICE_ID
	) {
		return {
			promotionId: PromoConst.FULL_PRICE_ID,
			promotionName: 'Full price',
		};
	}
	return state.promo.promos[state.selectedMultiSelectOptions.selectedPromoId];
}

export const selectSelectedTicketType = (priceLevelId) => (state) => {
	const priceLevel = state.priceLevel.priceLevels[priceLevelId] || null;
	if (priceLevel) {
		return (
			priceLevel.ticketTypes.find(
				(type) => type.name === state.selectedMultiSelectOptions.selectedTicketTypeName
			) || null
		);
	}
	return null;
};

export const selectSelectedTicketRule = (ticketTypeId) => (state) => {
	const selectedPromo = selectSelectedPromo(state);
	if (selectedPromo && selectedPromo.ticketRules) {
		return (
			selectedPromo.ticketRules.find(
				(rule) => rule.ticketTypeId === ticketTypeId
			) || null
		);
	}
	return null;
};

// thunk to set the selected ticket type name and the promo if the promo does not exist on the new ticket type
export function setSelectedTicketTypeName(ticketTypeName) {
	return async (dispatch, getState) => {
		// Dispatch the action to set the selected ticket type name
		await dispatch(
			selectedMultiselectOptions.actions.setSelectedTicketTypeName(
				ticketTypeName,
			),
		);

		// Get the updated state
		const state = getState();

		// Filter the promos based on the selected ticket type name
		const filteredPromos = selectFilteredPromos(state);

		// Get the selected promo ID from the state
		const selectedPromoId = state.selectedMultiSelectOptions.selectedPromoId;

		// Check if the selected promo ID is in the filtered promos
		const promoExists = filteredPromos.some(
			(promo) => promo.promotionId === selectedPromoId,
		);

		// If the selected promo ID is not in the filtered promos, set it to the first promo in the filtered promos
		if (!promoExists && filteredPromos.length > 0) {
			dispatch(setSelectedPromoId(filteredPromos[0].promotionId));
		}

	};
}

export async function setDefaultSelected(dispatch, getState) {
	const state = getState();
	const priceLevels = Object.values(state.priceLevel.priceLevels).sort(
		(a, b) => a.displayOrder - b.displayOrder,
	);
	const promos = state.promo.promos;

	for (const priceLevel of priceLevels) {
		const ticketTypeWithPrice = [...priceLevel.ticketTypes]
			.sort((a, b) => a.displayOrder - b.displayOrder)
			.find((ticketType) => ticketType.price !== null);

		if (ticketTypeWithPrice) {
			dispatch(
				selectedMultiselectOptions.actions.setSelectedTicketTypeName(
					ticketTypeWithPrice.name,
				),
			);
			dispatch(setSelectedPromoId(PromoConst.FULL_PRICE_ID));
			return;
		}
	}

	for (const priceLevel of priceLevels) {
		for (const ticketType of [...priceLevel.ticketTypes].sort(
			(a, b) => a.displayOrder - b.displayOrder,
		)) {
			const promo = Object.values(promos).find((promo) =>
				promo.ticketRules.some(
					(rule) => rule.ticketTypeId === ticketType.ticketTypeId,
				),
			);

			if (promo) {
				dispatch(
					selectedMultiselectOptions.actions.setSelectedTicketTypeName(
						ticketType.name,
					),
				);
				dispatch(setSelectedPromoId(promo.promotionId));
				return;
			}
		}
	}
}
