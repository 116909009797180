let customRule = (value, arg) => {
	return !!arg(value)
}
let Rules = {
	required: (value) => ValidationUtils.isNotEmpty(value),
	max: (value, arg) => parseFloat(value) <= parseFloat(arg),
	min: (value, arg) => parseFloat(value) >= parseFloat(arg),
	greaterThanZero: (value) => parseFloat(value) > 0,
	range: (value, minVal, maxVal) => {
		return Rules.min(value, minVal) && Rules.max(value, maxVal)
	},
	url: () => false,
	email: (value) => ValidationUtils.isValidEmail(value),
	date: () => false,
	extension: () => false,
	currency: (value) => ValidationUtils.isCurrencyAmount(value),
	number: (value) => ValidationUtils.isNumber(value),
	numeric: (value) => ValidationUtils.isNumber(value),
	digits: (value) => ValidationUtils.isDigits(value),
	minlength: (value, arg) =>
		(value != null && value.toString().length >= parseInt(arg)) ||
		parseInt(arg) <= 0,
	maxlength: (value, arg) =>
		value != null &&
		value.toString().length <= parseInt(arg) &&
		parseInt(arg) >= 0,
	rangelength: (value, min, max) =>
		Rules.minlength(value, min) && Rules.maxlength(value, max),
	phone: (value) => ValidationUtils.validPhone(value),
	phoneUS: (value) => ValidationUtils.validPhone(value),
	'custom-validate': customRule,
	custom: customRule,
	creditcard: (value) => CreditCardUtils.isValidCardNumber(value),
	'credit-card-number': (value) => CreditCardUtils.isValidCardNumber(value),
	'expire-date': (value) => CreditCardUtils.isValidExpirationDate(value),
	'expire-month': (value) => CreditCardUtils.isValidExpireMonth(value),
	'expire-year': (value) => CreditCardUtils.isValidExpireYear(value),
	regex: (value, regex) => ValidationUtils.matchesRegex(value, regex),
	zipcode: (value) => ValidationUtils.validZipCode(value),
	nowhitespace: (value) => ValidationUtils.hasNonWhiteSpace(value),
	cvv: (value) => CreditCardUtils.isValidCvv(value),
}
let Messages = {
	'custom-validate': 'Custom Validation failed',
	range: (rule, arg1, arg2) => `The value must be between ${arg1} and ${arg2}`,
	'choice-required': 'Value must be one of supplied choices.',
	url: null,
	date: null,
	extension: null,
	max: null,
	min: 'The amount specified is lower than the allowed minimum amount.',
	number: 'Please enter valid number.',
	currency: 'A valid monetary value must be supplied to 0 or 2 decimal places.',
	digits: null,
	minlength: null,
	maxlength: (rule, arg1) =>
		`Please enter characters less than or equal to ${arg1}`,
	rangelength: null,
	'expire-month': 'Exipre date month must be a future year',
	'expire-year': 'Exipre date year must be a valid future year',
	regex: null,
	greaterThanZero: null,
}

export class ValidationUtils {
	static applyRule(
		props,
		ruleName,
		value,
		validationResults = { isAllValid: true }
	) {
		let k = `data-rule-${ruleName}`
		if (props[k]) {
			var rule = Rules[ruleName]
			if (!rule) {
				throw new Error(`Rule does not exist: ${ruleName}`)
			}
			if (Array.isArray(props[k])) {
				validationResults[ruleName] = rule.apply(this, [value, ...props[k]])
			} else {
				validationResults[ruleName] = rule(value, props[k])
			}
			validationResults.isAllValid =
				validationResults.isAllValid && validationResults[ruleName]
		}
		return validationResults
	}
	static validateValue(props, value, skipRules) {
		var validationResults = { isAllValid: true }
		skipRules = skipRules || []
		let ruleRequired = props['data-rule-required'] || props['required']
		if (!ruleRequired && !value) {
			let applicableRules = ['custom', 'custom-validate']
			applicableRules.forEach((ruleName) => {
				ValidationUtils.applyRule(props, ruleName, value, validationResults)
			})
			return validationResults
		}
		//check required first
		if (ruleRequired && skipRules.indexOf('required') === -1) {
			validationResults['required'] = Rules['required'](value)
			validationResults.isAllValid =
				validationResults.isAllValid && validationResults['required']
		}
		// now skip it
		skipRules = skipRules.concat(['required'])
		for (var k in props) {
			if (k.startsWith('data-rule-')) {
				var ruleName = k.substr('data-rule-'.length)
				if (skipRules && skipRules.length && skipRules.indexOf(ruleName) >= 0) {
					continue
				}
				if (props[k]) {
					ValidationUtils.applyRule(props, ruleName, value, validationResults)
				}
			}
		}
		return validationResults
	}
	/**
	 * Removes all spaces from a string
	 */
	static removeSpaces(string) {
		return string.split(' ').join('')
	}

	/**
	 * Removes all dashes from a string
	 */
	static removeDashes(string) {
		return string.split('-').join('')
	}

	/**
	 * Removes all dashes and spaces from a string
	 */
	static removeExcessCharacters(string) {
		var output = ValidationUtils.removeSpaces(string)
		output = ValidationUtils.removeDashes(output)
		return output
	}

	/**
	 * Sanitizes a field to prepare it for validation
	 */
	static cleanField(value) {
		return ValidationUtils.removeExcessCharacters(value)
	}
	static validPhone(value) {
		return /^(\d{1,3}[-.]?)?(\(\d{3}\)|\d{3})[- .]?\d{3}[- .]?\d{4}$/.test(
			value
		)
	}
	static validZipCode(value) {
		return /^\d{5}(?:-\d{4})?$/.test(value)
	}
	static validSimpleZipCode(value) {
		return /^\d{5}$/.test(value)
	}
	static isNumber(value) {
		return /^-?(\d+|(\d*(\.\d+)?))$/.test(value)
	}
	static isDigits(value) {
		return /^\d+$/.test(value)
	}
	static isCurrencyAmount(value) {
		return /^-?(\d+|(\d*(\.\d{0,2})?))$/.test(value)
	}
	static isNotEmpty(value) {
		return !!value
	}
	static hasNonWhiteSpace(value) {
		return !!value.trim()
	}
	static matchesRegex(value, regex) {
		var regexObj = new RegExp(regex)
		return regexObj.test(value)
	}

	static isValidEmail(value) {
		return /^\S+@\S+\.[a-zA-Z0-9]{2,5}$/.test(value)
	}

	static getValidationMessage(ruleName, ...args) {
		let message = Messages[ruleName]
		if (typeof message === 'function') {
			message = message(ruleName, ...args)
		}
		return message
	}
}

export class CreditCardUtils {
	static getCreditCardType() {}
	static isValidCvv(value) {
		return /^\d{3,4}$/.test(value)
	}
	static isValidExpirationDate(value) {
		value = ValidationUtils.removeSpaces(value)
		var valid = /^\d{1,2}\/\d{2}$/.test(value)
		var components = value.split('/')
		var month = parseInt(components[0])
		var year = parseInt(components[1])
		var currentDate = new Date()
		valid = valid && month >= 1 && month <= 12
		var currentYear = currentDate.getFullYear()
		var thousands = Math.floor(currentYear / 1000) * 1000
		year = thousands + Math.floor((currentYear - thousands) / 100) * 100 + year
		var inFuture =
			year > currentDate.getFullYear() ||
			(year === currentDate.getFullYear() &&
				month >= currentDate.getMonth() + 1)
		return valid && inFuture
	}
	static isValidExpireMonth(value) {
		var valid = /^\d{2}$/.test(value)
		var month = parseInt(value)
		return valid && month >= 1 && month <= 12
	}
	static isValidExpireYear(value) {
		var valid = /^\d{2}$/.test(value)
		var year = parseInt(value)
		var currentYear = new Date().getFullYear()
		var thousands = Math.floor(currentYear / 1000) * 1000
		year = thousands + Math.floor((currentYear - thousands) / 100) * 100 + year
		return valid && year >= currentYear
	}
	static isValidCardNumber(value) {
		value = ValidationUtils.cleanField(value)
		var ccNumberPattern = /^\d{12,20}$/
		if (!ccNumberPattern.test(value)) return false

		var sumTable = new Array(2)
		sumTable[0] = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9)
		sumTable[1] = new Array(0, 2, 4, 6, 8, 1, 3, 5, 7, 9)
		var sum = 0
		var flip = 0

		for (var i = value.length - 1; i >= 0; i--) {
			sum += sumTable[flip++ & 0x1][value.charAt(i)]
		}

		if (sum % 10 != 0) return false

		return true
	}
}
export let updateMessages = function (I18n) {
	Object.assign(Messages, {
		required: I18n.getKey('errors.requiredField'),
		email: I18n.getKey('errors.invalidEmail'),
		phone: I18n.getKey('errors.invalidPhoneUS'),
		phoneUS: I18n.getKey('errors.invalidPhoneUS'),
		creditcard: I18n.getKey('errors.invalidCardNumber'),
		'credit-card-number': I18n.getKey('errors.invalidCardNumber'),
		'expire-date': I18n.getKey('errors.invalidCCDate'),
		zipcode: I18n.getKey('errors.invalidZip'),
		cvv: I18n.getKey('errors.invalidCvv'),
		nowhitespace: I18n.getKey('errors.requiredField'),
	})
}
export const ValidationMessages = Messages
