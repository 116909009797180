export const colors = {
	primary: {
		first: '#722A85',
		second: 'rgba(114, 42, 133, 0.6)',
		third: 'rgba(114, 42, 133, 0.1)',
		faded: '#F9EEFC'
	},
	secondary: {
		first: '#FF9602',
		second: 'rgba(255, 150, 2, 0.6)',
		third: 'rgba(255, 150, 2, 0.1)',
	},
	surfaces: {
		surface: '#FFFFFF',
		background: '#F2F6FC',
		alternate: '#F9F7FF',
	},
	backgrounds: {
		primary: '#111013',
		second: 'rgba(17, 16, 19, 0.6)',
		third: 'rgba(17, 16, 19, 0.1)',
	},
	outlines: {
		error: '#FF2D55',
		warning: '#FF9602',
		success: '#36C75A',
		primary: '#007AFF',
		secondary: '#FF9602',
		outline: 'rgba(17, 16, 19, 0.15)'
	},
	borders: {
		black: 'rgba(0, 0, 0, 0.15)',
	},
	accordions: {
		background: 'rgba(248, 249, 250, 1)'
	},
	blue: {
		primary: 'rgba(8, 23, 81, 1)'
	},
	error: '#B00020',
	warning: '#FF981A',
	success: '#7ED321',
	scrim: 'rgba(17, 16, 19, 0.32)',

	white: '#FFFFFF',
	black: '#000000',
	lightGrey: '#F8F9FA',
	grey: '#E4E4E4',
}

export const theme = {
	fontFamilies: {
		primary: 'Inter, sans-serif',
	},
	fontWeight: {
		regular: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
	},
	fontSizes: {
		xxs: '10px',
		xs: '12px',
		sm: '14px',
		md: '16px',
		lg: '20px',
		xl: '24px',
		xxl: '34px',
		xxxl: '48px',
		xxxxl: '60px',
		largest: '96px',
	},
	spacing: {
		xxs: '4px',
		xs: '8px',
		sm: '12px',
		md: '16px',
		lg: '24px',
		xl: '32px',
		xxl: '40px',
		xxxl: '48px',
	},
	boxShadows: {
		shadow1: '0px 1px 1px 0px rgba(0, 0, 0, 0.14)',
		shadow2: '0px 2px 2px 0px rgba(0, 0, 0, 0.14)',
		shadow3: '0px 3px 4px 0px rgba(0, 0, 0, 0.14)',
		shadow4: '0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
		shadow5: '0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
		shadow6: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
		shadow7: '0px 9px 12px 1px rgba(0, 0, 0, 0.14)',
		shadow8: '0px 12px 17px 2px rgba(0, 0, 0, 0.14)',
		shadow9: '0px 16px 24px 2px rgba(0, 0, 0, 0.14)',
		shadow10: '0px 24px 38px 3px rgba(0, 0, 0, 0.14)',
	},
	padding: {
		xxs: '4px',
		xs: '6px',
		sm: '8px',
		smmd: '12px',
		md: '16px',
		lg: '24px',
		mdlg: '30px',
		xl: '32px',
		xxl: '40px',
		xxxl: '50px',
	},
	margin: {
		xxs: '4px',
		xs: '6px',
		sm: '8px',
		smmd: '12px',
		md: '16px',
		lg: '24px',
		xl: '32px',
	},
	borderRadius: {
		sm: '4px',
		md: '8px',
		lg: '16px',
		xl: '24px',
		xllg: '28px',
	},
}