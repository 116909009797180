import { createSlice } from '@reduxjs/toolkit';
import { selectTicketTypes } from './priceLevelSlice';
import { setDefaultSelected } from './selectedMultiselectOptions';
import { PromoConst } from 'util/Constants';

const initialState = {
	promos: {}, // map of promo id to promo object
};

// This slice has not been tested completely, so be warned: you may need to fix some small issues.
export const promoSlice = createSlice({
	name: 'promo',
	initialState,
	reducers: {
		setPromos: (state, action) => {
			// for now, we are cloning because redux will freeze the object, but in the future, we wouldn't need to because everything will just use this state
			state.promos = structuredClone(action.payload);
		},
	},
});

export default promoSlice.reducer;

export function setPromos(promos) {
	return async (dispatch) => {
		await dispatch(promoSlice.actions.setPromos(promos));
		await dispatch(setDefaultSelected);
	};
}

// also includes a special promo "full price" if one exists
export function selectFilteredPromos(state) {
	const selectedTicketTypeName =
		state.selectedMultiSelectOptions.selectedTicketTypeName;
	if (!selectedTicketTypeName) return []; // Early return if no selection

	const promos = Object.values(state.promo.promos);
	const ticketTypes = selectTicketTypes(state);

	const selectedTicketTypes = ticketTypes.filter(
		(ticketType) => ticketType.name === selectedTicketTypeName,
	);
	const hasFullPrice = selectedTicketTypes.some(
		(ticketType) => ticketType.price !== null,
	);

	const filteredPromos = promos.filter((promo) =>
		promo.ticketRules.some((rule) =>
			ticketTypes.some(
				(ticketType) =>
					ticketType.ticketTypeId === rule.ticketTypeId &&
					ticketType.name === selectedTicketTypeName,
			),
		),
	);

	if (hasFullPrice) {
		filteredPromos.push({
			promotionId: PromoConst.FULL_PRICE_ID,
			promotionName: 'Full price',
			displayOrder: -1,
		});
	}

	return filteredPromos.sort((a, b) => a.displayOrder - b.displayOrder);
}
