import React from 'react'
import { EditorConstants } from 'components/seatingChart/editor/EditorConstants'

const { ACTION_TOOLS } = EditorConstants

export class EditableObject extends React.PureComponent {
	moveControlPoint() {
		return false
	}

	handleDragEnd() {
		// if is not editing...
		if (!this.props.selectedTool.action === ACTION_TOOLS[1]) {
			return false
		}
		const beforeValue = this.state.beforeValue
		if (typeof this.props.onMoveEnd === 'function') {
			const value = Object.assign({}, this.props.data)
			this.props.onMoveEnd(value, beforeValue)
		}
		this.setState({ editMode: null })
	}

	handleDrag(state) {
		// if is not editing...
		if (!this.props.selectedTool.action === ACTION_TOOLS[1]) {
			return false
		}
		return this.moveControlPoint(state, this.state.editMode)
	}

	handleDragStart() {
		return false
	}

	handleUnselect() {
		return false
	}

	getPosition() {
		const { data, position } = this.props
		if (!position) {
			return {
				posX: data.posX,
				posY: data.posY,
			}
		}
		return position
	}

	editObject(func) {
		console.log('EDIT OBJECT', func)
		const { onResizeObject, data, selectedTool } = this.props
		const isEditing = selectedTool.action === ACTION_TOOLS[1]

		this.setState({ beforeValue: Object.assign({}, data) })
		if (isEditing && typeof onResizeObject === 'function') {
			this.setState({ editMode: func })
			onResizeObject(this)
		}
	}
}
