import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getFeatureFlagsAll = async () => {
    const token = Cookies.get('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    try {
        const response = await axios.get(`${API_BASE_URL}/auth/v1/getFeatureFlagsAll`,  { headers });
        return response.data;
    } catch (error) {
        console.error('Error getFeatureFlagsAll :', error);
        return false;
    }
};
