import BaseActionCreator from 'factory/BaseActionCreator';
import { configureStore } from '@reduxjs/toolkit';
import { seatingChartReducer } from 'stores/calendar/SeatingChartStore';
import promoReducer from '../slices/promoSlice';
import selectedTicketsReducer from '../slices/selectedTicketsSlice';
import priceLevelReduer from '../slices/priceLevelSlice';
import selectedMultiselectOptionsReducer from '../slices/selectedMultiselectOptions';
import featureFlagReducer from '../slices/featureFlagSlice';

export const ReduxStore = configureStore({
	reducer: {
		seatingChartReducer,
		promo: promoReducer,
		priceLevel: priceLevelReduer,
		selectedTickets: selectedTicketsReducer,
		selectedMultiSelectOptions: selectedMultiselectOptionsReducer,
		featureFlag: featureFlagReducer,
	},
});

BaseActionCreator.setDispatcher(ReduxStore);
