import React from 'react'
import PropTypes from 'prop-types'
import { ValidationUtils } from 'util/ValidationAddons'

/**
 * A form element is the base class for form components to work with the form component
 * Form elements do not necessarily need to be classical dom form elements. Each form element
 * must have a name and value in order to be included in a Form input.
 *
 * Form elements also have validation built in. The rules are passed to the element as data-rule-<rulename> props.
 * see ValidationAddons for specific rule details. The value for these props are the additional arguments to the
 * validation function in validation addons. Custom messages may also be passed to the element via data-msg-<rulename>
 * props.
 */
export class FormElement extends React.Component {
	constructor(props) {
		super(props)
		this.state = { isValid: true }
		this._onMount = this.componentDidMount || (() => {})
		this.componentDidMount = this._componentDidMount
		this._onBeforeUnmount = this.componentWillUnmount || (() => {})
		this.componentWillUnmount = this._componentWillUnmount
	}

	/**
	 * _componentDidMount - when an element is mounted, register itself with the parent form if applicable
	 * and gets a unique id from the form
	 */
	_componentDidMount() {
		this._onMount.apply(this, arguments)
		if (this.parentForm) {
			this.uniqueId = this.parentForm.addElement(this)
		}
	}
	/**
	 * _componentWillUnmount - when an element is unmounted, unregister itself with the parent form if applicable
	 */
	_componentWillUnmount() {
		this._onBeforeUnmount.apply(this, arguments)
		if (this.parentForm) {
			this.parentForm.removeElement(this)
		}
	}
	get selectionValue() {
		return this.value
	}

	/**
	 * get formId - when the component is registerd with its parent form, its assigned a unique id within the form.
	 * This returns that id
	 *
	 * @return {number}  unique id of the element in the form
	 */
	get formId() {
		return this.uniqueId
	}

	/**
	 * get allowMultiple - a boolean that tells the form to turn the value of this and any other element with the
	 * same name into an array to allow multiple values. Otherwise elements with the same name will be overridden depending on the order of
	 * the elements.
	 *
	 * @return {boolean}  if the form element should allow multiple values
	 */
	get allowMultiple() {
		return this.props.allowMultiple
	}

	/**
	 * get values - some form elements may have multiple values. THis is the value actually used in generating
	 * the form object.
	 *
	 * @return {object[]}  a list of values of the current element. by default it is only a single value array.
	 * Subclasses must override either this or value prop.
	 */
	get values() {
		return [this.value]
	}

	/**
	 * get value - this is the value of the element. THis should overridden by extending classes, and either
	 * value or values MUST be overridden
	 *
	 * @return {object}  the value of the element this will be passed as a field in the Form submit event
	 */
	get value() {
		return null
	}

	/**
	 * get name - The name of the form element. This is used as the key in the form object.
	 * Distinct names are not necessarily required, name conflicts may be
	 * overridden or be an array depending on the form option selected. See FOrm for more details.
	 *
	 * @return {string}  name of the form element
	 */
	get name() {
		return this.props.name
	}

	/**
	 * get fullName - Returns the fully qualified name of the field. Because of the ability of form nesting. The fq name
	 * includes the name of all parent forms if the form chain. In other words this is the path of this element value in
	 * the root form object value.
	 *
	 * @return {string}  description
	 */
	get fullName() {
		if (this.parentForm && this.parentForm.props.name) {
			return `${this.parentForm.props.name}.${this.name}`
		}
		return this.name
	}

	/**
	 * get parentForm - returns the form component that directly contains this form element. In otherwords, the form that owns this
	 * component. May be null for standalone elements
	 *
	 * @return {Form}  description
	 */
	get parentForm() {
		return this.props.parentForm || (this.context && this.context.parentForm)
	}

	/**
	 * get rootForm - returns the form component that is at the root of the form chain. This may
	 * be null for standalone elements.
	 *
	 * @return {Form}  the Form component at the root of the form chain
	 */
	get rootForm() {
		return this.props.parentForm || (this.context && this.context.rootForm)
	}

	/**
	 * get cssClass - returns a string for the css class to render on the element
	 *
	 * @return {string}  a css class to render
	 */
	get cssClass() {
		var errorClass = !this.state.isValid
			? this.props.errorClass || 'ot_ci_input_error'
			: this.props.validClass || 'ot_ci_input_success'
		return `${this.state.showError ? errorClass : ''} ${this.props.className || ''}`
	}

	/**
	 * get isValid - returns if an element is valid
	 *
	 * @return {boolean}  true if an element is currently valid false otherwise
	 */
	get isValid() {
		return this.validate().valid
	}

	/**
	 * get validateOnChange - can be overridden be extending classes. overrides the validateOnChange property to always
	 * force validation on change for an extending class. It requires onChange defined on the element.
	 *
	 * @return {boolean}  true if the element should force change validation, false otherwise
	 */
	get validateOnChange() {
		return false
	}

	/**
	 * clearItem - clears the element. Depending on the clild element, this may have no tangible effect. This forces a re validation
	 */
	clearItem() {
		console.log('CLEARITEM')
		this.setState({ value: null }, () => {
			this.validate()
		})
	}

	/**
	 * emptyItem - makes text input empty
	 */
	emptyItem() {
		this.setState({ value: '' }, () => {
			this.validate()
		})
	}

	/**
	 * skipSubmit - returns true if the element should not be included in the submission. by default if no name is
	 * provided, submission will be skipped
	 *
	 * @return {boolean}  true if value should NOT be included in form submission. false otherwise
	 */
	skipSubmit() {
		if (typeof this.props.skipSubmit === 'function') {
			return this.props.skipSubmit(this.value, this)
		}
		if (typeof this.props.skipSubmit === 'boolean') {
			return this.props.skipSubmit
		}
		return false
	}
	notifyChange() {
		if (
			this.validateOnChange ||
			this.props.validateOnChange ||
			(this.props.rootForm && this.rootForm.props.validateOnChange)
		) {
			this.fullValidate()
		} else {
			this.validate({ stateUpdate: false })
		}
		if (this.props.fullValidateOnChange && this.parentForm) {
			this.parentForm.fullValidate({
				showErrors: !!this.props.fullValidateShowErrors,
			})
		}
		if (this.parentForm && this.parentForm.props.fullValidateOnChange) {
			this.parentForm.fullValidate({
				showErrors: !!this.parentForm.props.fullValidateShowErrors,
			})
		}
		if (
			this.rootForm &&
			(this.props.submitOnChange || this.rootForm.props.submitOnChange)
		) {
			this.forceUpdate(() => this.rootForm.submit())
		}
	}
	onChange(e) {
		this.notifyChange()
		if (this.props.onChange) {
			this.props.onChange(e, this.value, this)
		}
	}
	onBlur(e) {
		if (this.props.onBlur) {
			this.props.onBlur(e, this)
		}
		if (
			this.props.validateOnBlur !== false ||
			(this.parentForm && this.parentForm.props.validateOnBlur !== false)
		) {
			this.fullValidate()
		} else {
			this.validate({ stateUpdate: false })
		}
		if (this.props.fullValidateOnBlur && this.parentForm) {
			this.parentForm.fullValidate({
				showErrors: !!this.props.fullValidateShowErrors,
			})
		}
		if (this.parentForm && this.parentForm.props.fullValidateOnBlur) {
			this.parentForm.fullValidate({
				showErrors: !!this.parentForm.props.fullValidateShowErrors,
			})
		}
		if (
			this.rootForm &&
			(this.props.submitOnBlur || this.rootForm.props.submitOnBlur)
		) {
			this.forceUpdate(() => this.rootForm.submit())
		}
	}

	/**
	 * getMessageForValidation - given the resutls of an element validation, returns the message for the validation.
	 *
	 * @param  {object} validation an object describing the results of the element validation,from validate().
	 * @return {string}            a validation error message if applicable
	 */
	getMessageForValidation(validation) {
		return this.getValidationMessage(null, validation)
	}

	/**
	 * getValidationMessage - returns the error message text for a given validation rule
	 *
	 * @param  {string} ruleName          the name of the rule to get the message for. if null, will return the message for the first error in validationResults.
	 * @param  {object} validationResults required if ruleName is null. The result object of element validation,
	 *                                   will get violated rule name from this object.
	 * @return {string}                 an error message for a violated rule
	 */
	getValidationMessage(ruleName, validationResults) {
		if (!ruleName) {
			if (!this.state.validationResults) {
				return null
			}
			var { ...errors } = validationResults || this.state.validationResults
			ruleName = Object.keys(errors).filter((key) => !errors[key])[0]
		}
		let parameters = this.props['data-rule-' + ruleName]
		//allows the message to be a function.
		if (typeof this.props['data-msg-' + ruleName] === 'function') {
			if (parameters && Array.isArray(parameters)) {
				// pass in the value and the parameters for the rule for appropriate messaging
				return this.props['data-msg-' + ruleName](this.value, ...parameters)
			}
			return this.props['data-msg-' + ruleName](this.value, parameters)
		} else if (typeof this.props['data-msg-' + ruleName] !== 'undefined') {
			return this.props['data-msg-' + ruleName]
		}
		if (parameters && Array.isArray(parameters)) {
			return ValidationUtils.getValidationMessage(ruleName, ...parameters)
		}
		return ValidationUtils.getValidationMessage(ruleName, parameters)
	}

	/**
	 * clearValidation - hides the error label for the element
	 */
	clearValidation() {
		this.setState({ showError: false })
	}

	/**
	 * forceError - forces an invalidation of a form element with a given rule name. The element will be put into an
	 * invalid state, but the parent may not be notified.
	 *
	 * @param  {string} rule the name of the rule to force a validation error with
	 */
	forceError(rule) {
		var validationResults = { isAllValid: false }
		if (rule) {
			validationResults[rule] = false
		}
		this.setState({ validationResults, isValid: validationResults.isAllValid })
	}
	/**
	 * forceClean - forces the element into a valid state
	 */
	forceClean() {
		var validationResults = { isAllValid: true }
		this.setState({ validationResults, isValid: validationResults.isAllValid })
	}

	/**
	 * fullValidate - performs validation on the element and displays any messages associated with the validation state
	 * The UI will be updated for the element.
	 *
	 * @param  {object} options an object of validation options
	 * @return {object}         the result of the validation
	 */
	fullValidate(options) {
		options = options || {}
		options.noNotify = options.noNotify || false
		this.setState({ showError: true })
		return this.validate(options)
	}

	/**
	 * getValidationResults - helper method for performing validation for a value. This may need to be overriden for
	 * multivalued elements.
	 * @return {boolean}         true if element value is valid. False otherwise.
	 */
	getValidationResults() {
		return ValidationUtils.validateValue(this.props, this.value)
	}

	/**
	 * validate - validates a form element. This notifies the parent form of any validation state changes. returns
	 * an object describing the result of the vaslidation, inclding rules violated, number of elements invalid in the
	 * case of Sub forms. and the overall validation message.
	 *
	 * @param  {object} options options to the validate method
	 * @return {object}         an object describing the results of the validation
	 */
	validate(options) {
		options = options || {}
		options.noNotify = options.noNotify || false
		var validationResults = this.getValidationResults(options)
		var ret = {
			invalidElementCount: !validationResults.isAllValid ? 1 : 0,
			valid: validationResults.isAllValid,
			message: this.getMessageForValidation(validationResults),
			element: this,
			invalidElements: !validationResults.isAllValid ? [this] : [],
		}
		if (options.stateUpdate !== false) {
			//update the state unless forbidden by options
			this.setState({
				validationResults,
				isValid: validationResults.isAllValid,
			})
		}
		if (typeof this.props.validStateChanged === 'function') {
			//callback for after validate on a form element
			this.props.validStateChanged(
				validationResults.isAllValid,
				this.name,
				ret,
				validationResults
			)
		}
		if (this.parentForm && !options.noNotify) {
			//notify the parent form in case of a validation state change
			this.parentForm.notifyElementValidationState(this.name, ret)
		}
		return ret
	}
}

/**
 * forms are passed via context to allow formatting and structural elemnts between the parent forms
 * and the element
 */
FormElement.contextTypes = {
	rootForm: PropTypes.object,
	parentForm: PropTypes.object,
}
