import BaseActionCreator from 'factory/BaseActionCreator'
import { CalendarEvents } from 'actions/Events'
import Cookies from 'js-cookie'

const STATUS_CODE_MESSAGES = {
	8033: 'No Tickets Available',
	// Add more status codes and messages as needed
}

class CalendarActionCreator extends BaseActionCreator {
	constructor() {
		super('json')
	}

	loadSeatingChartById(seatingChartId) {
		return this.get('/SeatingChart(' + seatingChartId + ')').then((data) => {
			this.dispatch({
				type: CalendarEvents.SEATING_CHART_BY_ID_LOADED,
				data,
			})
			return data
		})
	}

	loadSeatingChart(performanceId, clientId, deepLink = false, setErrorMessage) {
		let params = ''
		if (deepLink) {
			params = '?deepLink=true'
		}
		return this.get(
			`/Performance(${performanceId})/seatingChart${params}`,
			null,
			{ clientId: clientId, newCIRequest: true }
		).then(
			(data) => {
				let priceLevels = data.priceLevels
				data.sections.forEach((section) =>
					section.rows.forEach((row) =>
						row.seats.forEach(
							(seat) => (seat.priceLevel = priceLevels[seat.priceLevel])
						)
					)
				)
				setErrorMessage(null)
				this.dispatch({ type: CalendarEvents.SEATING_CHART_LOADED, data: data })
				return data
			},
			(data) => {
				let errorMessage =
					STATUS_CODE_MESSAGES[data.data.statusCode] ||
					data.data.message ||
					'No Tickets Available'
				setErrorMessage(errorMessage)
				this.dispatch({ type: CalendarEvents.NO_SEATING_CHART, data })
			}
		)
	}

	async fetchSeatingChartData(
		performanceId,
		clientId,
		deeplink,
		setErrorMessage
	) {
		const token = Cookies.get('token')
		const baseUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_CONFIG_SC_URL}${performanceId}`

		try {
			const res = await this.get(
				baseUrl,
				null,
				{
					clientId: clientId,
					Authorization: 'Bearer ' + token,
				},
				{ constructUrl: false }
			)

			let priceLevels = res.priceLevels
			res.sections.forEach((section) =>
				section.rows.forEach((row) =>
					row.seats.forEach(
						(seat) => (seat.priceLevel = priceLevels[seat.priceLevel])
					)
				)
			)

			setErrorMessage && setErrorMessage(null)
			this.dispatch({
				type: CalendarEvents.SEATING_CHART_LOADED,
				data: res,
			})

			return res
		} catch (error) {
			setErrorMessage && setErrorMessage(error)
		}
	}
}

export default new CalendarActionCreator()
