import { BeizerFunction, LinearFunction } from 'util/MathFunctions';
import {
    SEAT_SIZE,
    SEAT_SPACING,
} from 'components/seatingChart/shared/SeatingChartConfig';

class SeatingUtils {
    static findSeatPosition(sectionSize, width, section, row, seatId) {
        let { skewY, seatAlign, curve, minPosition: sectionOffset } = section;

        let seat = row.seats.filter((seat) => seat.id === seatId)[0];
        if (!seat) {
            return null;
        }
        const skew = new LinearFunction(
            skewY,
            skewY < 0 ? -skewY * (sectionSize - 1) : 0
        );
        let curveGen = new BeizerFunction([0, 0], [width, 0], sectionSize, [
            width / 2,
            curve * 2,
        ]);
        const seatSpacing = new LinearFunction(
            SEAT_SIZE + SEAT_SPACING,
            SEAT_SPACING
        );
        const position = this.getActualPosition(
            row,
            seat.position,
            seatAlign,
            sectionSize,
            sectionOffset
        );
        const x = seatSpacing.evaluateAt(position);
        const y = curveGen.evaluateAtStep(position)[1] + skew.evaluateAt(position);
        return [x, y];
    }

    static getActualPosition(row, position, seatAlign, sectionSize, sectionOffset) {
        let offset = 0;
        let { maxPosition, minPosition } = row;
        maxPosition -= sectionOffset;
        minPosition -= sectionOffset;
        position -= sectionOffset;
        const positions = maxPosition - minPosition + 1;
        switch (seatAlign) {
            case 'LEFT':
                position = position - minPosition;
                break;
            case 'RIGHT':
                offset = sectionSize - positions;
                position = position - minPosition + offset;
                break;
            case 'CENTER':
                offset = (sectionSize - positions) / 2;
                position = position - minPosition + offset;
                break;
        }
        return position;
    }

    static calculateLabelPosition(data, { width, curve, skewY, sectionSize, sectionOffset, seatAlign = 'LEFT' }) {
        let { maxPosition, minPosition } = data;
        maxPosition -= sectionOffset;
        minPosition -= sectionOffset;
        let position = 0;
        position -= sectionOffset;
        const positions = maxPosition - minPosition + 1;
        const skew = new LinearFunction(
            skewY,
            skewY < 0 ? -skewY * (sectionSize - 1) : 0
        );
        let curveGen = new BeizerFunction([0, 0], [width, 0], sectionSize, [
            width / 2,
            curve * 2,
        ]);
        let seatSpacing = new LinearFunction(SEAT_SIZE + SEAT_SPACING, SEAT_SPACING);
        position = minPosition;
        let offset = 0;
        switch (seatAlign) {
            case 'LEFT':
                position = position - minPosition;
                break;
            case 'RIGHT':
                offset = sectionSize - positions;
                position = position - minPosition + offset;
                break;
            case 'CENTER':
                offset = (sectionSize - positions) / 2;
                position = position - minPosition + offset;
                break;
        }
        return [
            seatSpacing.evaluateAt(position - 1),
            curveGen.evaluateAtStep(position)[1] +
            skew.evaluateAt(position) +
            SEAT_SIZE / 4,
        ];
    }
}

export default SeatingUtils;