import React from 'react';
import styled from 'styled-components';
import { colors, theme } from 'theme';

const Wrapper = styled.div`
	background-color: ${colors.surfaces.alternate};
	border-radius: ${theme.borderRadius.xllg};
	padding: ${theme.padding.lg};
`;

export function AVCard({ children, style, className }) {
	return (
		<Wrapper className={className} style={style}>
			{children}
		</Wrapper>
	);
}
