import React from 'react';
import { MdLocationOn } from 'react-icons/md';
import { AVCard } from 'components/AVCard';
import { TicketTypePromoDropdown } from 'components/seatingChart/MultiSelect/TicketTypePromoDropdown';
import styled from 'styled-components';
import { ResetButton } from 'components/form/buttons/ResetButton';
import { setDefaultSelected } from '../../../slices/selectedMultiselectOptions';
import { useDispatch } from 'react-redux';
import { theme } from 'theme';
import {PriceLevelsList} from "components/seatingChart/MultiSelect/PriceLevelsList";

const SeatsCard = styled(AVCard)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: ${theme.margin.lg};
	z-index: 10;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const IconContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledTicketTypePromoDropdown = styled(TicketTypePromoDropdown)`
	margin-left: ${theme.margin.lg};
	position: relative;
	top: 10px;
`;

export function MultiselectToolbar() {
	const dispatch = useDispatch();
	return (
		<React.Fragment>
			<SeatsCard>
				<Container>
					<IconContainer>
						<MdLocationOn size={30} />
						<span style={{ textAlign: 'center' }}>Seats</span>
					</IconContainer>
					<StyledTicketTypePromoDropdown />
					<ResetButton onClick={() => dispatch(setDefaultSelected)} />
				</Container>
			</SeatsCard>
			<PriceLevelsList />
		</React.Fragment>
	);
}
