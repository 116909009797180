import React from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import styled from 'styled-components';
import { colors, theme } from 'theme';

const Wrapper = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 24px;
	padding: ${theme.padding.sm};
	padding-left: ${theme.padding.md};
	padding-right: ${theme.padding.md};
	margin: ${theme.margin.smmd};
	font-size: ${theme.fontSizes.md};
	border-radius: ${theme.borderRadius.xllg};
	border-width: 1px;
	border-style: solid;
	border-color: ${(props) =>
		props.isOpen ? colors.primary.first : colors.outlines.outline};
	background-color: ${(props) =>
		props.isOpen ? colors.surfaces.alternate : 'white'};
	color: ${(props) => (props.isOpen ? colors.primary.first : 'auto')};
`;

export function DropdownButton({
	isOpen,
	showTriangle,
	children,
	onClick = () => {},
	className,
}) {
	return (
		<Wrapper isOpen={isOpen} className={className} onClick={onClick}>
			{children}
			{showTriangle && <DropdownTriangle isOpen={isOpen} />}
		</Wrapper>
	);
}

const StyledFaCaretUp = styled(FaCaretUp)`
	color: rgb(114, 42, 133);
	font-size: 16px;
`;

const StyledFaCaretDown = styled(FaCaretDown)`
	color: rgb(114, 42, 133);
	font-size: 16px;
`;

const TriangleWrapper = styled.div`
	width: 25px;
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-left: auto;
`;

export function DropdownTriangle({ isOpen, style, className }) {
	return (
		<TriangleWrapper style={style} className={className}>
			{isOpen && <StyledFaCaretUp />}
			{!isOpen && <StyledFaCaretDown />}
		</TriangleWrapper>
	);
}
