import { createSlice } from '@reduxjs/toolkit';
import { setDefaultSelected } from './selectedMultiselectOptions';
import { PromoConst } from 'util/Constants';

const initialState = {
	priceLevels: {}, // a map of price levels with the id as the key
};

// This slice has not been tested completely, so be warned: you may need to fix some small issues.
export const priceLevelSlice = createSlice({
	name: 'priceLevel',
	initialState,
	reducers: {
		setPriceLevels: (state, action) => {
			// for now, we are cloning because redux will freeze the object, but in the future, we wouldn't need to because everything will just use this state
			state.priceLevels = structuredClone(action.payload);
		},
	},
});

export default priceLevelSlice.reducer;

export function setPriceLevels(priceLevels) {
	return async (dispatch) => {
		await dispatch(priceLevelSlice.actions.setPriceLevels(priceLevels));
		await dispatch(setDefaultSelected);
	};
}

export function selectTicketTypes(state) {
	const promos = state.promo.promos;
	return Object.values(state.priceLevel.priceLevels)
		.flatMap((priceLevel) => priceLevel.ticketTypes)
		.filter(
			(ticketType) =>
				ticketType.price !== null ||
				Object.values(promos).some((promo) =>
					promo.ticketRules.some(
						(rule) => rule.ticketTypeId === ticketType.ticketTypeId,
					),
				),
		);
}

export function selectTicketTypeNames(state) {
	const promos = state.promo.promos;
	const addedTicketTypes = new Set();

	return Object.values(state.priceLevel.priceLevels)
		.sort((a, b) => a.displayOrder - b.displayOrder)
		.flatMap((priceLevel) =>
			[...priceLevel.ticketTypes]
				.sort((a, b) => a.displayOrder - b.displayOrder)
				.filter(
					(ticketType) =>
						(ticketType.price !== null ||
							Object.values(promos).some((promo) =>
								promo.ticketRules.some(
									(rule) => rule.ticketTypeId === ticketType.ticketTypeId,
								),
							)) &&
						!addedTicketTypes.has(ticketType.name),
				)
				.map((ticketType) => {
					addedTicketTypes.add(ticketType.name);
					return ticketType.name;
				}),
		);
}

// returns an array of price level Ids that are possible with the currently selected ticketTypeName and promoId
export function selectAllowablePriceLevels(state) {
	const { selectedTicketTypeName, selectedPromoId } =
		state.selectedMultiSelectOptions;
	if (!selectedTicketTypeName || !selectedPromoId) return [];

	const priceLevels = Object.values(state.priceLevel.priceLevels);
	const promos = Object.values(state.promo.promos);

	return priceLevels
		.filter((priceLevel) => {
			const ticketType = priceLevel.ticketTypes.find(
				(ticketType) => ticketType.name === selectedTicketTypeName,
			);

			// price doesn't contain a ticketType with the same name as the selected ticket type
			if (!ticketType) return false;

			// the ticket type has a price and the selected promo is the full price
			if (
				ticketType.price !== null &&
				selectedPromoId === PromoConst.FULL_PRICE_ID
			) {
				return true;
			}

			// the ticket type doesn't have a price, so check if the ticket type has a promo with the selectedPromoId
			return promos.some(
				(promo) =>
					promo.promotionId === selectedPromoId &&
					promo.ticketRules.some(
						(rule) => rule.ticketTypeId === ticketType.ticketTypeId,
					),
			);
		})
		.map((priceLevel) => priceLevel.id);
}
