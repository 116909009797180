import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	// value should be a set of ticket ids
	ids: [],
};

// This slice has not been tested completely, so be warned: you may need to fix some small issues.
export const selectedTicketsSlice = createSlice({
	name: 'selectedTickets',
	initialState,
	reducers: {
		selectTicket: (state, action) => {
			state.ids.push(action.payload);
		},
		selectMultipleTickets: (state, action) => {
			state.ids = state.ids.concat(action.payload);
		},
		unselectTicket: (state, action) => {
			state.ids = state.ids.filter((id) => id !== action.payload);
		},
		clearSelections: (state) => {
			state.ids = [];
		},
	},
});

export const {
	selectTicket,
	selectMultipleTickets,
	unselectTicket,
	clearSelections,
} = selectedTicketsSlice.actions;

export default selectedTicketsSlice.reducer;
