import { EditorSection } from 'components/seatingChart/editor/EditorSection'
import {
	SEAT_SIZE,
	SEAT_RADIUS,
	SEAT_SPACING,
} from 'components/seatingChart/shared/SeatingChartConfig'
import { LinearFunction, VectorUtils, BeizerFunction } from 'util/MathFunctions'

export class EditorSectionOutline {
	static rowPoints(section) {
		const { skewX, skewY, minPosition, maxPosition, curve, rows } = section

		const ret = {
			startPositions: [],
			endPositions: [],
		}

		let min = Number.MAX_VALUE,
			max = -1

		let minItems = [],
			maxItems = []

		const sectionSize = maxPosition - minPosition + 1

		const width = sectionSize * SEAT_SIZE + (sectionSize + 1) * SEAT_SPACING

		const rowY = EditorSection.getRowYFunction(section)

		const rowX = new LinearFunction(SEAT_SIZE + SEAT_SPACING)

		const skew_x = new LinearFunction(
			skewX || 0,
			skewX < 0 ? -skewX * rows.length : 0
		)

		const skew = new LinearFunction(
			skewY,
			skewY < 0 ? -skewY * (sectionSize - 1) : 0
		)

		const curveGen = new BeizerFunction(
			[-SEAT_RADIUS + -SEAT_SPACING, 0],
			[width, 0],
			sectionSize,
			[(maxPosition + minPosition) / 2, curve * 2]
		)

		for (let i = 0, j = rows.length; i < j; i++) {
			const row = rows[i]

			const rowSize = row.maxPosition - row.minPosition + 1

			const rowPct = rowSize / sectionSize

			const startPosition = EditorSectionOutline.computePosition(
				section,
				row,
				rowSize
			)

			const endPosition = startPosition + rowSize

			const startSeatX = rowX.evaluateAt(startPosition)

			const endSeatX = rowX.evaluateAt(endPosition)

			const startSeatPosition = [
				skew_x.evaluateAt(i) + startSeatX,
				rowY.evaluateAt(row.rowNumber) +
					curveGen.evaluateAt(startSeatX)[1] +
					skew.evaluateAt(startPosition),
			]

			const endSeatPosition = [
				skew_x.evaluateAt(i) + endSeatX,
				rowY.evaluateAt(row.rowNumber) +
					curveGen.evaluateAt(endSeatX)[1] +
					skew.evaluateAt(endPosition),
			]

			if (i === 0) {
				ret.startPositions.push({
					position: startPosition,
					row: i + 1,
					x: startSeatPosition[0],
					y: startSeatPosition[1],
					rowPct,
				})

				ret.endPositions.push({
					position: endPosition,
					row: i + 1,
					x: endSeatPosition[0] + SEAT_SIZE,
					y: endSeatPosition[1],
					rowPct,
				})
			}

			if (i === section.rows.length - 1) {
				ret.startPositions.push({
					position: startPosition,
					row: i + 1,
					x: startSeatPosition[0],
					y: startSeatPosition[1] + SEAT_SIZE,
					rowPct,
				})

				ret.endPositions.push({
					position: endPosition,
					row: i + 1,
					x: endSeatPosition[0] + SEAT_SIZE,
					y: endSeatPosition[1] + SEAT_SIZE,
					rowPct,
				})
			}

			if (i === 0 || i === section.rows.length - 1) {
				continue //rest of method unecessary
			}

			if (startPosition <= min) {
				if (startPosition < min) {
					minItems = []
				}
				if (endPosition > max) {
					maxItems = []
				}
				min = startPosition
				minItems.push({
					position: startPosition,
					row: i + 1,
					x: startSeatPosition[0],
					y: startSeatPosition[1],
					rowPct,
				})
			}

			if (endPosition >= max) {
				if (endPosition > max) {
					maxItems = []
				}
				max = endPosition
				maxItems.push({
					position: endPosition,
					row: i + 1,
					x: endSeatPosition[0] + SEAT_SIZE,
					y: endSeatPosition[1],
					rowPct,
				})
			}
		}
		ret.startPositions.splice(1, 0, ...minItems) // this.filterPositions(startPositions);
		ret.endPositions.splice(1, 0, ...maxItems)
		ret.endPositions.reverse() // this.filterPositions(endPositions);

		return ret
	}

	static computePosition(section, row, rowSize) {
		const sectionSize = section.maxPosition - section.minPosition + 1
		switch (section.seatAlign) {
			case 'LEFT':
				return section.minPosition
			case 'RIGHT':
				return sectionSize - rowSize
			case 'CENTER':
				return (sectionSize - rowSize) / 2
		}
		return row.minPosition
	}

	static renderOutline(positions, isStart) {
		return positions.reduce((prev, item, index) => {
			const prefix = index === 0 && isStart ? 'M' : 'L'
			const { x, y } = item
			return `${prev} ${prefix} ${x} ${y}`
		}, '')
	}

	static getSectionOutline(section, regenerate = false) {
		const { curve, outline } = section
		if (!regenerate && outline) {
			return outline
		}

		const { startPositions, endPositions } =
			EditorSectionOutline.rowPoints(section)

		const cornerPoints = [
			startPositions[0],
			endPositions[endPositions.length - 1],
			startPositions[startPositions.length - 1],
			endPositions[0],
		]

		const bottomLine = new LinearFunction(
			VectorUtils.fromObject(cornerPoints[2], 'x', 'y'),
			VectorUtils.fromObject(cornerPoints[3], 'x', 'y')
		)

		const bottomControlPoint = bottomLine.pointAboveMidPoint(
			cornerPoints[3].x,
			cornerPoints[2].x,
			2 * curve * startPositions[startPositions.length - 1].rowPct
		)

		const topLine = new LinearFunction(
			VectorUtils.fromObject(cornerPoints[0], 'x', 'y'),
			VectorUtils.fromObject(cornerPoints[1], 'x', 'y')
		)

		const topControlPoint = topLine.pointAboveMidPoint(
			cornerPoints[0].x,
			cornerPoints[1].x,
			2 * curve * startPositions[0].rowPct
		)

		const generatedOutline = `
    ${EditorSectionOutline.renderOutline(startPositions, true)}
    L ${cornerPoints[2].x} ${cornerPoints[2].y}
    Q ${bottomControlPoint[0]} ${bottomControlPoint[1]} ${cornerPoints[3].x} ${cornerPoints[3].y}
    L ${cornerPoints[3].x} ${cornerPoints[3].y}
    ${EditorSectionOutline.renderOutline(endPositions, false)}
    Q ${topControlPoint[0]} ${topControlPoint[1]} ${cornerPoints[0].x} ${cornerPoints[0].y}
    `

		return generatedOutline.replace(/(\r\n\t|\n|\r\t)/gm, '')
	}
}
