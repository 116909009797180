import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const addToCart = async (seatObj) => {
    const token = Cookies.get('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/websocket/addSeat`, seatObj, { headers });
        return response.status === 200;
    } catch (error) {
        console.error('Error websocket/addSeat :', error);
        return false;
    }
};

export const unSelectSeat = async (seats) => {
    try {
        const token = Cookies.get('token');
        if (!token) {
            console.error('No authentication token found');
            return false;
        }

        const response = await fetch(`${API_BASE_URL}/websocket/removeSeat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(seats),
        });

        if (!response.ok) {
            console.error(`Failed to unselect seat. Status: ${response.status}`);
            return false;
        }

        return true;
    } catch (error) {
        console.error('Error in unSelectSeat:', error);
        return false;
    }
};

export const patronHoldLoad = async (patronHoldTicket, seatObjects) => {
    const token = Cookies.get('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    const patronMessage = {
        patronId: patronHoldTicket.patron.id,
        firstName: patronHoldTicket.patron.firstName,
        lastName: patronHoldTicket.patron.lastName,
        patronHoldSeats: seatObjects
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/websocket/patronLoad`, patronMessage, { headers });
        return response.status === 200;
    } catch (error) {
        console.error('Error websocket/patronLoad :', error);
        return false;
    }
};

