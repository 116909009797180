import React from 'react';
import { GrPowerReset } from 'react-icons/gr';
import styled from 'styled-components';
import { theme } from 'theme';

const Button = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 24px;
	margin-right: ${theme.margin.smmd};
	margin-left: auto;
	font-size: ${theme.fontSizes.sm};
	color: red;
`;

const Icon = styled.div`
	color: red;
	font-size: ${theme.fontSizes.md};
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	padding-bottom: ${theme.padding.xxs};
`;

export function ResetButton({ onClick }) {
	return (
		<Button onClick={onClick}>
			<Icon>
				<GrPowerReset />
			</Icon>
			Reset
		</Button>
	);
}
