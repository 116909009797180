import React, { useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
	DropdownButton,
	DropdownTriangle,
} from 'components/form/buttons/DropdownButton';
import { useSelector } from 'react-redux';
import { TicketTypePromoSelection } from 'components/seatingChart/MultiSelect/TicketTypePromoSelection';
import { selectTicketTypeNames } from '../../../slices/priceLevelSlice';
import { selectSelectedPromo } from '../../../slices/selectedMultiselectOptions';
import styled from 'styled-components';
import { selectFilteredPromos } from '../../../slices/promoSlice';
import { theme } from 'theme';

const DropdownButtonRelative = styled(DropdownButton)`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: max-content;
`;

const MiddleSeperator = styled.div`
	margin-left: ${theme.margin.md};
	margin-right: ${theme.margin.md};
`;

export function TicketTypePromoDropdown({ style, className }) {
	const selectedTicketTypeName =
		useSelector(
			(state) => state.selectedMultiSelectOptions.selectedTicketTypeName,
		) ?? '';
	const selectedPromoName =
		useSelector(selectSelectedPromo)?.promotionName ?? '';
	const promos = useSelector(selectFilteredPromos);
	const ticketTypes = useSelector(selectTicketTypeNames);

	const promosList = promos.map((promo) => ({
		title: promo.promotionName,
		value: promo.promotionId,
	}));

	const ticketTypesList = ticketTypes.map((ticketType) => ({
		title: ticketType,
		value: ticketType,
	}));

	const [open, setOpen] = React.useState(false);
	const dropdownRef = useRef(null);

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div style={style} className={className} ref={dropdownRef}>
			<DropdownButtonRelative
				isOpen={open}
				showTriangle={false}
				onClick={() => setOpen(!open)}
			>
				<DropdownStatusElement
					title={'Ticket Type'}
					value={selectedTicketTypeName}
					isOpen={open}
				/>
				<MiddleSeperator>/</MiddleSeperator>
				<DropdownStatusElement
					title={'Promotion'}
					value={selectedPromoName}
					isOpen={open}
					end={true}
				/>
			</DropdownButtonRelative>
			<AnimatePresence>
				{open && (
					<motion.div
						initial={{ opacity: 0, height: 0 }}
						animate={{ opacity: 1, height: 'auto' }}
						exit={{ opacity: 0, height: 0 }}
						transition={{ duration: 0.3 }}
					>
						<TicketTypePromoSelection
							promos={promosList}
							ticketTypes={ticketTypesList}
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}

const DropdownStatusElementTitle = styled.div`
	position: absolute;
	overflow: hidden;
	font-size: ${theme.fontSizes.sm};
	top: -25px;
`;

const DropdownStatusElementFlexWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	max-width: 25vw;
	justify-content: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
`;

const DropdownStatusElementWrapper = styled.div`
	max-width: 100%;
`;

const ValueWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
`;

const Title = styled.div`
	color: black;
`;

const Value = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StyledDropdownTriangle = styled(DropdownTriangle)`
	margin-left: ${theme.margin.smmd};
	margin-right: ${theme.margin.smmd};
`;

function DropdownStatusElement({
	title,
	value,
	isOpen,
	className,
	end = false,
}) {
	return (
		<DropdownStatusElementFlexWrapper className={className} end={end}>
			<DropdownStatusElementWrapper>
				<DropdownStatusElementTitle>
					<Title>{title}</Title>
				</DropdownStatusElementTitle>
				<ValueWrapper>
					<Value> {value} </Value> <StyledDropdownTriangle isOpen={isOpen} />
				</ValueWrapper>
			</DropdownStatusElementWrapper>
		</DropdownStatusElementFlexWrapper>
	);
}
