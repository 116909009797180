import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { colors, theme } from 'theme';
import { ShowBarDownArrowIcon } from 'components/common/icons/SvgIcons';

const Wrapper = styled.div`
	display: flex;
	width: 48%;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
`;

const ListWrapper = styled.div`
	overflow-y: auto;
	width: 100%;
	max-height: 300px;
	position: relative;
`;

const Text = styled.div`
	height: ${theme.spacing.xxxl};
	line-height: ${theme.spacing.xxxl};
	padding-left: ${theme.padding.lg};
	padding-right: ${theme.padding.lg};
	display: block;
	box-sizing: border-box;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: ${theme.fontSizes.md};
	cursor: pointer;
	border-bottom: solid 1px ${colors.grey};
	background-color: ${(props) =>
		props.selected ? colors.primary.faded : colors.lightGrey};
	transition: background-color 0.3s;
`;

const ScrollIconContainerTop = styled.div`
	position: absolute;
	top: 30px;
	z-index: 10;
	width: 100%;
	display: flex;
	justify-content: center;
	transform: rotate(180deg);
`;

const ScrollIconContainerBottom = styled.div`
	position: absolute;
	bottom: 10px;
	z-index: 10;
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Title = styled.text`
	font-size: ${theme.fontSizes.md};
	position: relative;
	top: -10px;
	text-transform: uppercase;
`;

export function ScrollableList({
	list,
	title,
	style = {},
	onClick,
	selectedValue,
}) {
	const scrollRef = useRef(null);
	const [isAtTop, setIsAtTop] = useState(false);
	const [isAtBottom, setIsAtBottom] = useState(false);

	const handleScroll = () => {
		if (scrollRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
			const isScrollable = scrollHeight > clientHeight;
			setIsAtTop(isScrollable ? scrollTop === 0 : false);
			setIsAtBottom(
				isScrollable ? scrollTop + clientHeight >= scrollHeight : false,
			);
		}
	};

	// changing the list might affect the scroll position
	useEffect(() => {
		handleScroll();
	}, [list]);

	useEffect(() => {
		const currentRef = scrollRef.current;
		if (currentRef) {
			currentRef.addEventListener('scroll', handleScroll);
			handleScroll(); // Initial check
		}
		return () => {
			currentRef.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Wrapper style={style}>
			<Title>{title}</Title>
			{isAtBottom && (
				<ScrollIconContainerTop>
					<ShowBarDownArrowIcon height='8' width='32' />
				</ScrollIconContainerTop>
			)}
			<ListWrapper ref={scrollRef}>
				{list.map((item) => (
					<Text
						onClick={() => onClick(item.value)}
						key={item.value}
						selected={item.value === selectedValue}
					>
						{item.title}
					</Text>
				))}
			</ListWrapper>
			{isAtTop && (
				<ScrollIconContainerBottom>
					<ShowBarDownArrowIcon height='8' width='32' />
				</ScrollIconContainerBottom>
			)}
		</Wrapper>
	);
}
