import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BeizerFunction, LinearFunction } from 'util/MathFunctions';
import Seat from 'components/seatingChart/seatSelection/Seat';
import {
	SEAT_RADIUS,
	SEAT_SIZE,
	SEAT_SPACING,
} from 'components/seatingChart/shared/SeatingChartConfig';
import Tooltip from '../../common/tooltip/Tooltip';
import styled from 'styled-components';
import {
	selectSelectedPromo,
	selectSelectedTicketRule,
	selectSelectedTicketType,
} from '../../../slices/selectedMultiselectOptions';
import { selectFeatureFlag } from '../../../slices/featureFlagSlice';
import { PromoConst } from 'util/Constants';
import SeatingUtils from './SeatingUtils';
import { ReduxStore } from 'reducers/configureStore';

const SeatTooltip = styled.p`
	text-align: left;
`;

const Row = (props) => {
	const [state, setState] = useState({
		isTooltipVisible: false,
		tooltipX: 0,
		tooltipY: 0,
		tooltipSeat: null,
	});

	const isMultiSelectEnabled = useSelector(
		selectFeatureFlag('is-multi-select-enabled'),
	);

	let tooltipTimeout = null;

	const setTooltip = (isVisible = false, x = 0, y = 0, seat = null) => {
		setState({
			isTooltipVisible: isVisible,
			tooltipX: x,
			tooltipY: y,
			tooltipSeat: seat,
		});
	};

	const renderFloor = (seat) => {
		if (!seat?.row || !seat?.number) return;

		return (
			<>
				<SeatTooltip>
					Floor: {seat.row} {seat.number}
				</SeatTooltip>
				{props.selectedSeats[seat.id] ? (
					<SeatTooltip>In Cart</SeatTooltip>
				) : null}
			</>
		);
	};

	const renderHold = (seat) => {
		if (!seat?.ticket) return;

		return <SeatTooltip>{seat.ticket.holdMessage}</SeatTooltip>;
	};

	const renderClear = (seat) => {
		if (
			!seat ||
			!props.selectedSeats[seat.id] ||
			!seat.available ||
			!isMultiSelectEnabled
		)
			return;

		return <SeatTooltip>Click to Remove</SeatTooltip>;
	};

	const renderPrice = (seat) => {
		if (!seat) return null;

		const priceLevel = seat.priceLevel;
		if (!priceLevel) return null;

		const state = ReduxStore.getState();
		const selectedTicketType = selectSelectedTicketType(priceLevel.id)(state);
		if (!selectedTicketType || !selectedTicketType.ticketTypeId) return null;
		const selectedTicketRule = selectSelectedTicketRule(
			selectedTicketType.ticketTypeId,
		)(state);
		const selectedPromoId = selectSelectedPromo(state)?.promotionId ?? '';

		const ticketTypes = priceLevel.ticketTypes || [];
		if (ticketTypes.length === 0) return null;

		if (!selectedTicketType) return null;

		if (
			selectedTicketType.type === 2 &&
			selectedTicketRule &&
			selectedTicketRule.price == null
		) {
			return null;
		}

		const priceIncludingFees =
			selectedTicketType.price +
			selectedTicketType.convenienceFeeBO;

		let displayPrice;
		if (
			selectedPromoId !== PromoConst.FULL_PRICE_ID &&
			selectedTicketRule &&
			selectedTicketRule.price !== undefined
		) {
			if (selectedTicketRule?.facilityFeePrice) {
				displayPrice = (selectedTicketRule.price + selectedTicketRule.consumerFeeBO + selectedTicketRule.facilityFeePrice).toFixed(2);
			} else {
				displayPrice = selectedTicketRule.price.toFixed(2);
			}
		} else {
			displayPrice = priceIncludingFees.toFixed(2);
		}

		if (
			selectedPromoId === PromoConst.FULL_PRICE_ID &&
			selectedTicketType.price === null
		) {
			return null;
		}

		return <p style={{ textAlign: 'left' }}>${displayPrice}</p>;
	};

	const renderSeats = () => {
		let {
			width,
			curve,
			skewY,
			data,
			seatAlign,
			sectionSize,
			showSeatNumber,
			textRotation = 0,
			onEditSelect,
			sectionOffset,
		} = props;

		let { seats } = props.data;
		const skew = new LinearFunction(
			skewY,
			skewY < 0 ? -skewY * (sectionSize - 1) : 0,
		);
		let curveGen = new BeizerFunction([0, 0], [width, 0], sectionSize, [
			width / 2,
			curve * 2,
		]);
		let seatSpacing = new LinearFunction(
			SEAT_SIZE + SEAT_SPACING,
			SEAT_SPACING,
		);
		seats = seats.filter((seat) => !seat.killSeat);

		return seats.map((seat) => {
			let position = SeatingUtils.getActualPosition(
				data,
				seat.position,
				seatAlign,
				sectionSize,
				sectionOffset,
			);
			return (
				<React.Fragment key={seat.id}>
					<Tooltip
						x={state.tooltipX}
						y={state.tooltipY}
						isTooltipVisible={
							state.isTooltipVisible && state.tooltipSeat === seat
						}
						portalElement={'tooltip-root'}
					>
						{renderFloor(state.tooltipSeat)}
						{renderHold(state.tooltipSeat)}
						{renderClear(state.tooltipSeat)}
						{isMultiSelectEnabled && renderPrice(state.tooltipSeat)}
					</Tooltip>
					<svg
						onMouseEnter={(e) => {
							const rect = e.target.getBoundingClientRect();
							const x = rect.left - 5;
							const y = rect.top + 25;

							tooltipTimeout = setTimeout(
								() => setTooltip(true, x, y, seat),
								600,
							);
						}}
						onMouseLeave={() => {
							clearTimeout(tooltipTimeout);
							tooltipTimeout = setTimeout(() => {
								setTooltip(false);
							}, 150);
						}}
						x={seatSpacing.evaluateAt(position)}
						y={curveGen.evaluateAtStep(position)[1] + skew.evaluateAt(position)}
						width={SEAT_RADIUS * 2}
						height={SEAT_RADIUS * 2}
					>
						<Seat
							onEditSelect={onEditSelect}
							textRotation={textRotation}
							showSeatNumber={showSeatNumber}
							isKillSeat={seat.killSeat}
							isInCart={seat.inCart}
							selected={!!props.selectedSeats[seat.id]}
							{...props}
							data={seat}
						/>
					</svg>
				</React.Fragment>
			);
		});
	};

	return renderSeats();
};

export default Row;
