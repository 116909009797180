import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	featureFlags: {}, // a map of feature flags with the state as the value, and name as the key
};

const featureFlagSlice = createSlice({
	name: 'featureFlag',
	initialState,
	reducers: {
		setFeatureFlags: (state, action) => {
			state.featureFlags = action.payload;
		},
	},
});

export default featureFlagSlice.reducer;

export const { setFeatureFlags } = featureFlagSlice.actions;

export function selectFeatureFlag(flag) {
	return (state) => {
		return state.featureFlag.featureFlags[flag] || false;
	};
}
