import React from 'react'
import { SeatingChartObject } from '../../../components/seatingChart/shared/objects/SeatingChartObject'
import { Section } from '../../../components/seatingChart/seatSelection/Section'

export function getActivePriceLevels() {
	const { priceLevels } = this.props.data

	const sections = this.getRenderableSections()
	if (!sections) {
		return priceLevels
	}

	let activePriceLevelIds = []
	for (let sec of sections) {
		if (sec.availableSeats > 0) {
			activePriceLevelIds = activePriceLevelIds.concat(sec.priceLevelIds)
		}
	}

	return Object.values(priceLevels).filter((pl) =>
		activePriceLevelIds.includes(pl.id)
	)
}

export function getItems(activePriceLevels) {
	return Object.values(activePriceLevels)
		.map((pl) => {
			const tts = pl.ticketTypes.map((tt) => tt.price)
			const max = Math.ceil(Math.max(...tts))
			const min = Math.floor(Math.min(...tts))
			return { min, max, priceLevel: pl }
		})
		.sort(
			(pl1, pl2) => pl1.priceLevel.displayOrder - pl2.priceLevel.displayOrder
		)
}

export function createSelectionObject() {
	let {
		ticketTypeData: {
			selectedTicketTypes = {},
			order,
			orderTotal,
			orderTickets,
		},
	} = this.state
	let { selectedSeats } = this.state

	let selectedSeatsLength = Object.keys(selectedSeats).length,
		selectedTicketTypesLength = Object.keys(selectedTicketTypes).length

	let ttCopy = Object.assign({}, selectedTicketTypes)
	Object.keys(selectedSeats).forEach((key) => delete ttCopy[key])

	let isValid =
		selectedSeatsLength === selectedTicketTypesLength &&
		Object.keys(ttCopy).length === 0

	return {
		selectedSeatsLength,
		selectedSeats,
		selectedTicketTypesLength,
		selectedTicketTypes,
		order,
		orderTotal,
		orderTickets,
		isValid,
	}
}

export function handleFilterChange(priceLevels) {
	this.setState({
		priceLevelFilters: priceLevels,
		priceRangeFilterApplied: true,
		filtersLength: Object.keys(priceLevels).length,
	})
}

export function onSeatSelect(data) {
	if (data.inCart && typeof this.props.onSelectCartSeat === 'function') {
		this.props.onSelectCartSeat(data.id)
	} else {
		let selectedSeats = Object.assign({}, this.props.selectedSeats, {
			[data.id]: data,
		})
		if (typeof this.props.onSeatSelectionChange === 'function') {
			const request = this.createSelectionObject()
			const selectedSeatsLength = Object.keys(selectedSeats).length
			Object.assign(request, { selectedSeats, selectedSeatsLength })
			this.props.onSeatSelectionChange(request)
		}
	}
}

export function onSeatUnselect(data) {
	const selectedSeats = Object.assign({}, this.props.selectedSeats)
	delete selectedSeats[data.id]

	const showPriceLevels =
		this.state.showPriceLevels && Object.keys(selectedSeats).length > 0

	this.setState(
		{
			// selectedSeats,
			showPriceLevels,
		},
		() => {
			if (typeof this.props.onSeatSelectionChange === 'function') {
				const request = this.createSelectionObject()
				Object.assign(request, { selectedSeats })
				this.props.onSeatSelectionChange(request)
			}
			if (
				!this.state.onlySeatSelection &&
				!showPriceLevels &&
				typeof this.props.onStepRegress === 'function'
			) {
				this.props.onStepRegress()
			}
		}
	)
}

export function renderSection(section, index) {
	const showOutline = this.props.isSmallDevice
		? this.state.mobileScale.home >= this.state.mobileScale.current
		: this.props.scale.current === this.props.scale.home ||
			this.props.scale.current < this.props.scale.home

	const position = Section.getSectionPosition(section)

	const filtersLength =
		this.props.onlySeatSelection && this.state.selectedSeats
			? Object.keys(this.state.selectedSeats).length ===
				this.props.seatsToSelect
			: this.state.filtersLength

	const isTraditionalSeatingChart = this.props.data.traditional
	const selectedSeats = Object.assign({}, this.props.selectedSeats)

	return (
		<g transform={`translate(${position[0]} ${position[1]})`} key={index}>
			<Section
				showOutline={showOutline && isTraditionalSeatingChart}
				priceLevelFilters={this.state.priceLevelFilters}
				priceRangeFilterApplied={this.state.priceRangeFilterApplied}
				selectedSeats={selectedSeats}
				seatsToSelect={this.props.seatsToSelect}
				onlySeatSelection={this.props.onlySeatSelection}
				filtersLength={filtersLength}
				showSeatNumber={false}
				isFixedPackageSeating={this.props.isFixedPackageSeating}
				disabledSeatsForFixedPlusPackage={
					this.props.disabledSeatsForFixedPlusPackage
				}
				onSectionClick={!this.props.legacy && this.handleSectionClick}
				data={section}
				rotate={section.rotation}
				skewX={section.skewX}
				skewY={section.skewY}
				curve={section.curve}
				seatAlign={section.seatAlign}
				onSeatSelect={this.props.onSeatSelect}
				onSeatUnselect={this.props.onSeatUnselect}
				isSmallDevice={this.props.isSmallDevice}
				isMobile={this.props.isMobile}
				featureFlags={this.props.featureFlags}
			/>
		</g>
	)
}

export function renderObject(obj, index) {
	return (
		<SeatingChartObject
			key={index}
			data={obj}
			onClickLabel={this.handleLabelClick}
		/>
	)
}

export function renderObjects() {
	const objects = this.props.data.objects || []
	const sections = this.getRenderableSections().map((section) => section)
	const items = sections
		.concat(objects)
		.sort((obj1, obj2) => obj1.layer - obj2.layer)
	return items.map((obj, index) =>
		obj.type === 'SECTION' &&
		(obj.sectionType === 'C' || obj.sectionType === 'P') &&
		obj.priceLevelIds.length > 0
			? this.renderSection(obj, index)
			: this.renderObject(obj, index)
	)
}

export function getRenderableSections(sect) {
	if (sect) {
		return sect
	}
	const {
		data: { sections },
	} = this.props

	return sections
}

export function generateSeatObjectForAddToCart(selectedSeat, selectedTicket, selectedPromo, ticketForPricing, performanceId) {
	const promotion = selectedPromo;

    const promotionPrice = promotion?.price || 0;
    const promotionName =
        promotion?.name === 'Full price' ? null : promotion?.name || null;

    const seatObj = {
        sectionId: selectedSeat?.sectionId,
        selectedTicketTypeId: selectedTicket.ticketTypeId,
        selectedTicketRuleId:
            promotion?.ticketRuleId || selectedTicket.selectedTicketRuleId,
        promotionPrice: promotionPrice,
        promotionBOFee: promotion?.convenienceFeeBO || 0,
        promotionName: promotionName,
        name: selectedSeat.sectionName,
        promotionCode:
            promotion?.promotionCode ||
            ticketForPricing.promotionCode ||
            selectedTicket.promotionCode,
        promotionId:
            promotion?.promotionId ||
            ticketForPricing.promotionId ||
            selectedTicket.promotionId,
        seats: selectedSeat.id,
        performanceId: performanceId,
        seatType: selectedSeat?.priceLevel?.type,
        seatTypeInitial: 'available',
        type: 'MSG',
        number: selectedSeat.number,
        row: selectedSeat.row,
        rowNumber: selectedSeat.rowNumber,
        selectedTicketName: selectedTicket.name,
    };

    return seatObj;
}
