import React from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import PriceLevels from './PriceLevels';
import { OtColors } from 'util/OtColors';

const PriceLevelsCard = styled.div`
	position: absolute;
	top: 120px;
	left: 0;
	right: 0;
	margin: ${theme.margin.lg};
	background-color: ${OtColors.white};
	z-index: 9;
	border-radius: ${theme.borderRadius.lg};
	padding: ${theme.padding.mdlg};
	text-align: center;
`;

const LegendList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const PriceLevelsList = () => {
	return (
		<PriceLevelsCard>
			<LegendList>
				<PriceLevels />
			</LegendList>
		</PriceLevelsCard>
	);
};
