import React from 'react';
import { ScrollableList } from 'components/common/ScrollableList';
import { useDispatch, useSelector } from 'react-redux';
import {
	setSelectedPromoId,
	setSelectedTicketTypeName,
} from '../../../slices/selectedMultiselectOptions';
import styled from 'styled-components';
import { theme } from 'theme';

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: flex-start;
	position: absolute;
	background-color: white;
	top: 65px;
	width: 70vw;
	max-width: 600px;
	z-index: 10;
	border-radius: ${theme.borderRadius.lg};
	padding: ${theme.padding.mdlg};
	box-shadow: ${theme.boxShadows.shadow10};
`;

export function TicketTypePromoSelection({ promos, ticketTypes }) {
	const { selectedPromoId, selectedTicketTypeName } = useSelector(
		(state) => state.selectedMultiSelectOptions,
	);
	const dispatch = useDispatch();
	return (
		<Container>
			<ScrollableList
				title='Ticket Type'
				list={ticketTypes}
				selectedValue={selectedTicketTypeName}
				onClick={(value) => dispatch(setSelectedTicketTypeName(value))}
			/>

			<ScrollableList
				title='Promotion'
				list={promos}
				selectedValue={selectedPromoId}
				onClick={(value) => dispatch(setSelectedPromoId(value))}
			/>
		</Container>
	);
}
